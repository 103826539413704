import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import * as URLS from "./urls";
import FullScreenLoading from "../components/helpers/Loaders/FullScreenLoading";
import GuestRoute from "./GuestRoute";

const Home = React.lazy(() => import("../pages/Home/Home"));
const AddEvent = React.lazy(() => import("../pages/AddEvent/AddEvent"));
const EventPage = React.lazy(() => import("../pages/EventPage/EventPage"));
const TermsAndConditionsPage = React.lazy(() =>
  import("../pages/TermsAndConditions/TermsAndConditions")
);
const HowToPostAnEvent = React.lazy(() =>
  import("../pages/HowToPostAnEvent/HowToPostAnEvent")
);
const Newsletter = React.lazy(() => import("../pages/Newsletter/Newsletter"));
const Login = React.lazy(() => import("../pages/Auth/Login"));

const router = createBrowserRouter([
  {
    path: URLS.HOME,
    element: (
      <GuestRoute>
        <Home />
      </GuestRoute>
    ),
  },
  {
    path: URLS.ADD_EVENT,
    element: (
      <GuestRoute>
        <AddEvent />
      </GuestRoute>
    ),
  },
  {
    path: URLS.EVENT_PAGE,
    element: (
      <GuestRoute>
        <EventPage />
      </GuestRoute>
    ),
  },
  {
    path: URLS.TERMS_AND_CONDITIONS,
    element: (
      <GuestRoute>
        <TermsAndConditionsPage />
      </GuestRoute>
    ),
  },
  {
    path: URLS.HOW_TO_POST_AN_EVENT,
    element: (
      <GuestRoute>
        <HowToPostAnEvent />
      </GuestRoute>
    ),
  },
  {
    path: URLS.NEWSLETTER,
    element: (
      <GuestRoute>
        <Newsletter />
      </GuestRoute>
    ),
  },
  {
    path: URLS.LOGIN,
    element: (
      <GuestRoute disableNav>
        <Login />
      </GuestRoute>
    ),
  },
]);

export default function Routes() {
  return (
    <Suspense fallback={<FullScreenLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
