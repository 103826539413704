import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import {
  HOW_TO_POST_AN_EVENT,
  NEWSLETTER,
  TERMS_AND_CONDITIONS,
} from "../../routes/urls";

export default function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.Footer__main}>
        <div className={styles.Footer__main__1}>
          <h3>Who We Are</h3>
          <div className={styles.Footer__main__1__links}>
            <Link to={"/"}>Our Story</Link>
            <Link to={NEWSLETTER}>Newsletter</Link>
            <Link to={"/"}>Careers</Link>
          </div>
        </div>
        <div className={styles.Footer__main__1}>
          <h3>Follow Us</h3>
          <div className={styles.Footer__main__1__links}>
            <Link to={"/"}>Facebook</Link>
            <Link to={"/"}>Instagram</Link>
            <Link to={"/"}>Tiktok</Link>
            <Link to={"/"}>Twitter</Link>
            <Link to={"/"}>YouTube</Link>
          </div>
        </div>
        <div className={styles.Footer__main__1}>
          <h3>Support</h3>
          <div className={styles.Footer__main__1__links}>
            <Link to={"/"}>Frequently Asked Questions</Link>
            <Link to={HOW_TO_POST_AN_EVENT}>Post An Event</Link>
            <Link to={TERMS_AND_CONDITIONS}>Terms And Conditions</Link>
            <Link to={"/"}>Contact Us</Link>
          </div>
        </div>
      </div>

      <div className={styles.Footer__copyright}>
        funfii &copy; {new Date().getFullYear()}
      </div>
    </div>
  );
}
