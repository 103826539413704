import React, { Suspense } from "react";
import LayoutContainer from "../components/Layout/LayoutContainer";
import FullScreenLoading from "../components/helpers/Loaders/FullScreenLoading";

export default function GuestRoute({ children, disableNav }) {
  return disableNav ? (
    <>
      <Suspense fallback={<FullScreenLoading />}>{children}</Suspense>
    </>
  ) : (
    <LayoutContainer>
      <Suspense fallback={<FullScreenLoading />}>{children}</Suspense>
    </LayoutContainer>
  );
}
