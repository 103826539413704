import React from "react";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faAdd,
  // faEnvelope,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  HOME,
  ADD_EVENT,
  NEWSLETTER,
  HOW_TO_POST_AN_EVENT,
} from "../../routes/urls";
import { useAuth } from "../../context/AuthProvider";

export default function Navbar() {
  const auth = useAuth();

  return (
    <div className={styles.Navbar}>
      <Link to={HOME} className={styles.Navbar__logo}>
        funffi
      </Link>

      <div className={styles.Navbar__ctas}>
        <div style={{ display: "flex", gap: "1.5rem" }}>
          <Link to={NEWSLETTER} className={styles.Navbar__ctas__login}>
            {/* <FontAwesomeIcon icon={faEnvelope} /> */}
            Newsletter
          </Link>

          {auth?.state?.isAuthenticated ? (
            <>
              <Link to={ADD_EVENT} className={styles.Navbar__ctas__login}>
                {/* <FontAwesomeIcon icon={faAdd} /> */}
                Post Event
              </Link>
              <button
                onClick={auth.logout}
                className={styles.Navbar__ctas__login}
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
              </button>
            </>
          ) : (
            <Link
              to={HOW_TO_POST_AN_EVENT}
              className={styles.Navbar__ctas__login}
            >
              {/* <FontAwesomeIcon icon={faAdd} /> */}
              Post Event
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
