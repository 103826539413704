export const getBase64Promise = (file) =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve({
        name: file?.name,
        data: reader?.result,
        size: file?.size,
      });
    };
    reader.onerror = function (error) {
      reject("Error: ", error);
    };
  });

export const getHeaders = (otherHeaders) => {
  const token = localStorage.getItem(process.env.REACT_APP_USERTOKEN);
  return {
    headers: {
      Authorization: token,
      ...otherHeaders,
    },
  };
};
